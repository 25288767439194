<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchUsers"></s-search>

    <div class="table-button">
      <!-- <a-button type="primary" @click="PiLiangSHZP"
        >批量审核纠正中照片</a-button
      > -->
      <!-- <a-button type="plain" icon="cloud-download" @click="handleExport"
        >导出</a-button
      > -->
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <!-- 表头设置 -->
      <columns-set
        tableName="CorrectionLog"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="pickup_time"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1700, y: 'calc(82vh - 150px )' }"
    >
      <!-- :scroll="{ x: 1000 }" -->
      <span slot="serial" slot-scope="text, record, index">
        <!-- {{ (pageNo - 1) * pageSize + index + 1 }} -->
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <!-- 工地名称 -->
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span @click="jumpToSiteList(text)" class="cursor_pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
      <!-- 照片 -->
      <span slot="s_event_file" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record.ws_name)">
          <div class="actions">
            <div class="icon_out">
              <img :src="text" width="100%" class="imgname" />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
        </span>
      </span>
      <!-- 纠正时间 -->
      <span slot="pickup_time" slot-scope="text"> {{ $Format(text) }} </span>
      <!-- 报警时间 -->
      <span slot="s_event_time" slot-scope="text"> {{ $Format(text) }} </span>
      <!-- 纠正状态 -->
      <span slot="pickup_status" slot-scope="text">{{
        text | SitCorrectStatus
      }}</span>
      <!-- 纠正事件 type -->
      <span slot="type" slot-scope="text">{{ text | sitTypeLog }}</span>
      <span slot="old_type" slot-scope="text">{{ text | oldType }}</span>
      <!-- 操作 -->
      <!-- <span slot="action" slot-scope="text, record, index">
        <a @click="handele(text, record, index)" href="javascript:;">查看</a>
      </span> -->
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">
        工地整洁度照片（{{ ImgName }}）
      </div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
    <!-- <log-jiuzheng
      :visible2="visible2"
      @LogJiuzheng="LogJiuzheng"
      :childValue="childValue"
    />
    <log-jiuzheng-pl
      :visible3="visible3"
      @LogJiuzhengPl="LogJiuzhengPl"
      :childValuePl="childValuePl"
    ></log-jiuzheng-pl> -->
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetAlarmEvent, GetPickuplog } from "@/api/image";
import { ExportWorkers } from "@/api/export";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";
import LogJiuzheng from "./LogJiuzheng.vue";
import LogJiuzhengPl from "@/views/image/LogJiuzhengPl.vue";
import moment from "moment";
// 搜索条件
const queryItems = [
  { label: "工地名称", value: "ws_name", type: "input" },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "纠正结果",
    value: "type",
    type: "select",
    selectOption: "SitLogType",
  },
  {
    label: "原纠正事件",
    value: "old_type",
    type: "select",
    selectOption: "OldLogType",
  },
  {
    label: "纠正人",
    value: "pickup_name",
    type: "input",
  },
  {
    label: "纠正状态",
    value: "pickup_status",
    type: "select",
    selectOption: "SitLogStatus",
  },
  {
    label: "纠正日期",
    value: "e_time",
    type: "dateRange",
    range: ["pickup_time_start", "pickup_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [
      // moment(moment(new Date()).format("YYYY-MM-DD")),
      // moment(moment(new Date()).format("YYYY-MM-DD")),
    ],
  },
];
const orderParam = [
  "ws_name",
  "bc_id",
  "type",
  "old_type",
  "pickup_time_start",
  "pickup_time_end",
  "pickup_name",
  "pickup_status",
  "pageNo",
  "pageSize",
];
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "纠正时间",
    width: "120px",
    dataIndex: "pickup_time",
    scopedSlots: { customRender: "pickup_time" },
    align: "center",
  },
  {
    title: "纠正结果",
    width: "140px",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    align: "center",
  },
  {
    title: "原纠正事件",
    width: "140px",
    dataIndex: "old_type",
    scopedSlots: { customRender: "old_type" },
    align: "center",
  },
  {
    title: "报警照片",
    width: "245px",
    dataIndex: "s_event_file",
    scopedSlots: { customRender: "s_event_file" },
  },
  {
    title: "原报警时间",
    width: "120px",
    dataIndex: "s_event_time",
    scopedSlots: { customRender: "s_event_time" },
    align: "center",
  },
  {
    title: "分公司",
    width: "110px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "工地管家",
    width: "90px",
    dataIndex: "ws_gj_name",
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "ws_jl_name",
    align: "center",
  },
  {
    title: "工地名称",
    // width: "400px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },

  {
    title: "纠正状态",
    width: "100px",
    dataIndex: "pickup_status",
    scopedSlots: { customRender: "pickup_status" },
    align: "center",
  },
  {
    title: "纠正人",
    width: "90px",
    dataIndex: "pickup_name",
    align: "pickup_name",
    fixed: "right",
  },
  // {
  //   title: "操作",
  //   width: "100px",
  //   // dataIndex: "e_score",
  //   scopedSlots: { customRender: "action" },
  //   dataIndex: "action",
  //   align: "center",
  //   ellipsis: true,
  // },
];

export default {
  name: "CorrectionLog",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    LogJiuzheng,
    LogJiuzhengPl,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        // bc_name: "",
        // ws_name: "",
        // e_events: "",
        // e_time: "",
        pickup_time_start: "",
        pickup_time_end: "",
      },
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      visible: false,
      confirmLoading: false,

      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 查看图片
      visibleImg: false,
      Image: "",
      ImgName: "",
      childValue: {},
      childValuePl: [],
      visible2: false,
      visible3: false,
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    // 默认值为七天前的日期和今天的日期
    var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
    this.queryItems[6].defaultValue = [
      moment(sevenTime),
      moment(moment(new Date())),
    ];
    this.queryParam.pickup_time_start = Math.round(sevenTime / 1000);
    this.queryParam.pickup_time_end = Math.round(new Date().getTime() / 1000);
  },
  watch: {
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("CorrectionLogColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo * 1;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);

      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      return GetPickuplog(params).then((res) => {
        res.data.pageNo = res.data.page * 1;
        res.data.totalCount = res.data.sum;

        const url = this.pathUrl;
        let data = res.data.data;
        // console.log(data);
        this.childValuePl = data;
        data.forEach((el, i) => {
          let img = el.s_event_file;
          el.s_event_file = url + img;
          // console.log(el.e_events);
        });
        return res.data;
      });
    },

    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true); //刷新
    },
    // handleExport() {
    //   this.visible = true;
    // },
    exportOk() {
      this.confirmLoading = true;
      const params = {
        bc_name: this.queryParam.bc_name,
        type: this.queryParam.type,
        pickup_time_start: this.queryParam.pickup_time_start,
        pickup_time_end: this.queryParam.pickup_time_end,
        pickup_status: this.queryParam.pickup_status,
        // face_status: this.queryParam.face_status,
        // e_score: this.queryParam.e_score,
      };

      ExportWorkers(params).then(() => {
        this.visible = false;
        this.confirmLoading = false;
      });
    },
    exportCancel() {
      this.visible = false;
    },
    showInfo(url, ws_name) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = ws_name;
      let that = this;
      that.Image = url;

      // this.Image = "";
      // let that = this;
      // requestImage(this.pathUrl + url, function (url) {
      // that.Image = url;
      // });
    },
    imgCancel() {
      this.visibleImg = false;
    },
    // 操作按钮
    handele(text, record, index) {
      // console.log(text, record, index);
      this.childValue = record;
      // console.log(this.childValue);
      this.visible2 = true;
    },
    LogJiuzheng(visible) {
      this.visible2 = visible;
    },
    LogJiuzhengPl(visible) {
      this.visible3 = visible;
    },
    PiLiangSHZP() {
      console.log("点击了批量审核照片");
      console.log(this.childValuePl);
      this.visible3 = true;
    },
    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_name: text,
        },
      });
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<style scoped lang="less">
imgname :hover {
  transform: translateY(-30px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.7);
}

// 鼠标悬停蒙层
.info_out {
  position: relative;
  // 鼠标移入变成小手
  cursor: pointer;
}
.icon_out {
  // width: 209px;
  width: 100%;
  height: 117px;
  position: relative;
  display: flex;
}
.icon {
  position: absolute;
  margin: auto;
  font-size: 25px;
  left: 43%;
  top: 40%;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;
    // width: 209px;
    width: 100%;
    height: 117px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
