<template>
  <div>
    <div id="model1">
      <a-modal
        :visible="visible2"
        :confirm-loading="confirmLoading"
        :cancel-button-props="{ style: { display: 'none' } }"
        :ok-button-props="{ style: { display: 'none' } }"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div slot="title" style="width: 100%">
          报警照片 （{{ this.childValue.ws_name }}）
        </div>
        <!-- 报警照片 -->
        <viewer>
          <div class="picture_wrap">
            <div class="pic">
              <img :src="this.childValue.e_img" alt="" />
            </div>
          </div>
        </viewer>
        <div>
          <div class="biaoti">
            <div class="biaoti1">报警事件</div>
            <div class="biaoti1 jiuzheng">
              <div><span>*</span>纠正</div>
            </div>
          </div>
          <div class="neirong">
            <div class="left">
              <div class="chouyan">
                摄像头歪曲 （
                {{
                  this.childValue.e_events.trim().split(" ")[0] == "摄像头歪曲"
                    ? this.childValue.e_score
                    : ""
                }}
                ）
              </div>
              <div class="chouyan gongzhuang">
                没穿工装 （
                {{
                  this.childValue.e_events.trim().split(" ")[0] == "没穿工装"
                    ? this.childValue.e_score.trim().split(" ")[0]
                    : ""
                }}
                ）
              </div>
              <div class="chouyan gongzhuang">
                有人吸烟 （
                {{
                  this.childValue.e_events.trim().split(" ")[2] == "有人吸烟"
                    ? this.childValue.e_score.trim().split(" ")[1]
                    : this.childValue.e_events.trim().split(" ")[0] ==
                      "有人吸烟"
                    ? this.childValue.e_score
                    : ""
                }}
                ）
              </div>
            </div>
            <div class="right">
              <div class="chouyan">
                <a-radio-group
                  name="radioGroup "
                  :value="`${
                    this.childValue.e_events.trim() == '摄像头歪曲'
                      ? ''
                      : '正常'
                  }`"
                  @change="changeradioValue2"
                >
                  <a-radio value="正常">正常</a-radio>
                </a-radio-group>
              </div>
              <div class="chouyan gongzhuang">
                <a-radio-group
                  name="radioGroup "
                  :value="`${
                    this.childValue.e_events.trim().split(' ').length == 3
                      ? ''
                      : this.childValue.e_events.trim() == '没穿工装'
                      ? ''
                      : '正常'
                  }`"
                  @change="changeradioValue2"
                >
                  <a-radio value="正常">正常</a-radio>
                </a-radio-group>
              </div>
              <div class="chouyan gongzhuang">
                <a-radio-group
                  name="radioGroup "
                  :value="`${
                    this.childValue.e_events.trim().split(' ').length == 3
                      ? ''
                      : this.childValue.e_events.trim() == '有人吸烟'
                      ? ''
                      : '正常'
                  }`"
                  @change="changeradioValue2"
                >
                  <a-radio value="正常">正常</a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="JZZT_out">
          <span>纠正状态：<span class="JZZT_red JZZT_I_R">1111</span></span
          ><span class="JZZT_I_L"
            >提交纠正时间：<span class="JZZT_I_R">2023-01-05 15:14</span></span
          ><span class="JZZT_I_L"
            >提交人：<span class="JZZT_I_R">admin</span></span
          >
        </div>

        <!-- 上一条下一条按钮 -->
        <div class="BTN">
          <div>
            <span>取消纠正后，可重复提交纠正事件</span>
            <span class="BTN_right">
              <a-button @click="TG_next()">取消纠正</a-button>
            </span>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    childValue: {
      type: Object,
      default: {},
    },
    visible2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ModalText: "报警图片",
      confirmLoading: false,
      editable: false,
      visible: "",
      YiShenHe: 1,

      value: "",
    };
  },
  mounted() {},

  methods: {
    // 确定按钮，已隐藏
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      console.log("handleok");
      setTimeout(() => {
        // this.visible = this.visible2;
        this.visible = false;
        this.$emit("LogJiuzheng", this.visible);
        this.confirmLoading = false;
      }, 500);
    },
    // 关闭弹窗
    handleCancel(e) {
      console.log("Clicked cancel button");
      // this.visible = this.visible2;

      this.visible = false;
      this.$emit("LogJiuzheng", this.visible);
    },

    changeradioValue(e) {
      console.log("111", e.target.value);
      // this.childValue.e_events = e.target.value;
    },
    // 按钮点击事件
    changeradioValue2(e) {
      console.log("111", e.target.value);
      // this.childValue.e_events = e.target.value;
      // this.childValue.e_events.trim().split(" ")[0] = e.target.value;
    },
    // 取消纠正按钮
    TG_next() {
      console.log("点击了取消纠正");
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
  // width: 787px;
  // width: 900px;
  height: 50%;
  top: 1px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
}
/deep/.ant-modal-content {
  width: 970px;
  // height: 80%;
  position: absolute;
  // top: 5%;
}
/deep/.ant-modal-body {
  // height: 625px;
  height: 765px;
}

.picture_wrap {
  margin: 0 10px 10px 10px;
  border-radius: 4px;
  display: flex;
}
.pic {
  min-width: 100px;
  min-height: 100px;
  width: 30%;
  margin-right: 1%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  img {
    width: 900px;
    // height: 600px;
    height: auto;
    margin: auto;
  }
}
.BTN {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  .BTN_right {
    .ant-btn {
      margin-left: 10px;
    }
  }
}
.biaoti {
  margin-top: 20px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  // border: solid 1px black;
  background-color: #f0f1f4;
  text-align: center;
  line-height: 40px;
  .biaoti1 {
    width: 50%;
    border: solid 1px black;
  }
  .jiuzheng {
    border-left: none;
    display: flex;

    span {
      color: red;
      margin-left: 200px;
      font-size: 18px;
    }
    a {
      margin-left: 100px;
    }
  }
}
.neirong {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .left,
  .right {
    width: 50%;
    border: solid 1px black;
    border-top: none;
  }
  .right {
    border-left: none;
  }
  .last {
    // background-color: pink;
    margin-top: 10px;
    width: 100%;
    text-align: right;
  }
}
.gongzhuang {
  border-top: 1px solid black;
}
.JZZT_out {
  margin-top: 70px;
  font-weight: 800;
  .JZZT_red {
    color: red;
  }
  .JZZT_I_L {
    margin-left: 30px;
  }
  .JZZT_I_R {
    font-weight: 500;
    margin-left: 5px;
  }
}
.BTN {
  margin-top: 10px;
  display: flex;
  justify-content: right;
  .BTN_right {
    .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
